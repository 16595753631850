import React, { Fragment } from "react";
import useStyles from "./HeroSection.styles";

import { Container, Typography, Box, Link } from "@material-ui/core";
import MainImg from "../../../../assets/img/main-section-img.png";

const HeroSection = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container maxWidth="lg">
        <Box mb={5}>
          <Typography className={classes.title} align="center" variant="h3">
            Satu Hati Satu Tujuan
          </Typography>
        </Box>
        <Box>
          <Typography
            className={classes.subtitle}
            align="center"
            variant="subtitle1"
          >
            White Horse has always been a friend to its customers, employees and
            shareholders <br /> who are always pursuing the best quality and
            reliability as a transportation service provider.
          </Typography>
        </Box>
        <Container maxWidth="lg">
          <Box display={"flex"} justifyContent={"center"}>
            <img src={MainImg} alt="main-image" className={classes.mainImg} />
          </Box>
        </Container>
      </Container>
    </Box>
  );
};

export default HeroSection;
