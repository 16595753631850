import React, { Fragment } from "react";
import useStyles from "./TripExplorer.styles";

import {
  Grid,
  Container,
  Typography,
  Box,
  Link,
  Button,
  Hidden,
} from "@material-ui/core";
import TripExplorerImg from "../../../../assets/img/trip-explorer.png";
import Logo from "../../../../assets/img/explorer-logo.png";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Analytics } from "../../../../components";

const TripExplorer = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <Box className={classes.root}>
        <Container maxWidth="lg">
          <Container maxWidth="lg">
            <Grid container spacing={4}>
              <Hidden mdUp>
                <Grid item xs={12}>
                  <img
                    src={TripExplorerImg}
                    className={classes.tripExplorerImg}
                  />
                </Grid>
              </Hidden>
              <Grid item xs={12} md={6}>
                <img src={Logo} className={classes.logo} />
                <Typography className={classes.title} variant="h4">
                  Trip <span>#AntiBosan</span>
                </Typography>
                <Typography
                  paragraph
                  className={classes.desc}
                  variant="subtitle1"
                  align="justify"
                >
                  explorer.id is a digital tour and travel company that
                  specializes in creating exciting and affordable domestic tour
                  packages throughout Indonesia. We offer both weekly scheduled
                  trips, which we call open trips, as well as private trips for
                  those who prefer to travel with their own group. To ensure the
                  highest level of comfort and safety for our passengers, we use
                  seats from Daytrans and buses from White Horse, both of which
                  are known for their reliability and quality. Let us take you
                  on an unforgettable journey through Indonesia today!
                </Typography>
                <a target="_blank" href="https://explorer.id">
                  <Button
                    onClick={() =>
                      Analytics("open_web_explorer_click", {
                        title: "Open Website Explorer",
                      })
                    }
                    variant="contained"
                    color="primary"
                    endIcon={<ChevronRightIcon />}
                  >
                    Open Website
                  </Button>
                </a>
              </Grid>
              <Hidden smDown>
                <Grid item xs={12} md={6}>
                  <img
                    src={TripExplorerImg}
                    className={classes.tripExplorerImg}
                  />
                </Grid>
              </Hidden>
            </Grid>
          </Container>
        </Container>
      </Box>
    </Fragment>
  );
};

export default TripExplorer;
