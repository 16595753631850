import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 150,
    marginBottom: 120,

    [theme.breakpoints.down("sm")]: {
      paddingTop: 110,
      marginBottom: 80,
    },
  },
  subtitle: {
    fontSize: "16px",
    color: "#939598",
  },
  mainSliderContainer: {
    width: "auto",
    height: "auto",
    position: "relative",
    marginLeft: 55,
    [theme.breakpoints.only("xs")]: {
      marginLeft: 30,
    },
    [theme.breakpoints.only("sm")]: {
      marginLeft: 40,
    },
  },
  collapseSlider: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    overflowX: "hidden",
    overflowY: "hidden",
  },

  btnIcon: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
  btnIconLeft: {
    backgroundColor: "#fff",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#fff",
      color: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
