import React from "react";
import useStyles from "./MainSection.styles";

import { Container, Typography, Box } from "@material-ui/core";

const MainSection = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container maxWidth="xl">
        <Box textAlign={"center"} mb={3}>
          <Typography variant="h5" color="secondary">
            Investor Relations
          </Typography>
        </Box>
        <Box textAlign={"center"} mb={5}>
          <Typography variant="h2">Investor Relations</Typography>
        </Box>
        <Box textAlign={"center"} mb={9}>
          <Typography variant="subtitle1" className={classes.subtitle}>
            White Horse Group known as PT WEHA Transportasi Indonesia, Tbk. is a
            publicly listed company (JAK: WEHA) with its main <br /> business in
            land transportation and holds ISO 9001:2008 and OHSAS 18001:2007
            certifications for all of its operations.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default MainSection;
