import React, { useState } from "react";
import useStyles from "./MainLayout.styles";
import { Footer, MobileDrawer, Navbar } from "../../components";
import { Hidden } from "@material-ui/core";

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const [mobileMenu, setMobileMenu] = useState(false);

  const handleDrawerMobileOpen = () => {
    setMobileMenu(true);
  };

  const handleDrawerMobileClose = () => {
    setMobileMenu(false);
  };

  return (
    <div className={classes.root}>
      {/* ---------- CONTENT WEBISTE ---------- */}
      <main className={classes.main}>
        {/* ---------- NAVBAR WEBSITE ---------- */}
        <header>
          <Hidden smDown>
            <Navbar handleDrawerMobileOpen={handleDrawerMobileOpen} />
          </Hidden>
          <Hidden mdUp>
            <MobileDrawer onClose={handleDrawerMobileClose} open={mobileMenu} />
          </Hidden>
        </header>
        {children}
      </main>

      {/* ---------- Footer WEBISTE ---------- */}
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default MainLayout;
