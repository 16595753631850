import React, { useState } from "react";
import useStyles from "./Penghargaan.styles";
import CHSELogo from "../../assets/img/chse-logo.png";
import ITTALogo from "../../assets/img/ITTA Award.png";
import GCGLogo from "../../assets/img/GCG Award.png";
import ISOLogo from "../../assets/img/ISO OHSAS.png";

import { Container, Typography, Box, Grid, Button } from "@material-ui/core";
import { AwardCards } from "./components";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const awardItems = [
  {
    id: 1,
    name: "CHSE",
    desc: "Sertifikasi CHSE Kemenparekraf Republik Indonesia",
    logo: CHSELogo,
  },
  {
    id: 2,
    name: "ITTA",
    desc: "Indonesia Travel & Tourism Award for Leading Bus Company 2022/23",
    logo: ITTALogo,
  },
  {
    id: 3,
    name: "GCG Award",
    desc: "Indonesia Most Trusted Companies 2011 (Gold) The Indonesian Institute for Corporate Governance & SWA Magazine",
    logo: GCGLogo,
  },
  {
    id: 4,
    name: "ISO OHSAS",
    desc: "Sertifikasi ISO 9001:2008 dan OHSAS 18001:2007",
    logo: ISOLogo,
  },
];

const Penghargaan = () => {
  const classes = useStyles();
  const [awards, setAwards] = useState(awardItems);

  const slideLeft = () => {
    const lastArray = awards.pop();
    setAwards([lastArray, ...awards]);
  };

  const slideRight = () => {
    setAwards([...awards.slice(1), awards[0]]);
  };

  return (
    <Box className={classes.root}>
      <Container maxWidth="xl">
        <Box textAlign={"center"} mb={3}>
          <Typography variant="h5" color="secondary">
            Awards
          </Typography>
        </Box>
        <Box textAlign={"center"} mb={5}>
          <Typography variant="h2">Awards</Typography>
        </Box>
        <Box textAlign={"center"} mb={9}>
          <Typography variant="subtitle1" className={classes.subtitle}>
            Commitment to excellence.
          </Typography>
        </Box>
      </Container>
      <Box>
        <div className={classes.mainSliderContainer}>
          <div className={classes.collapseSlider}>
            <AwardCards data={awards} />
          </div>
        </div>
      </Box>
      <Container maxWidth="lg">
        <Grid direction="row" container>
          <Grid item xs={12} container justifyContent="flex-end">
            <Button
              size="medium"
              color="secondary"
              className={classes.btnIconLeft}
              onClick={slideLeft}
            >
              <ArrowBackIcon />
            </Button>
            <Button
              color="primary"
              size="medium"
              className={classes.btnIcon}
              onClick={slideRight}
            >
              <ArrowForwardIcon />
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Penghargaan;
