import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 120,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 110,
      marginBottom: 80,
    },
    paddingTop: "80px",
    paddingBottom: "80px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
      paddingBottom: "50px",
      marginBottom: 80,
    },
  },
  subtitle: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#CDCECE",
  },
  title: {
    fontSize: "24px",
    color: "#fff",
  },
  email: {
    backgroundColor: "#fff",
    marginBottom: theme.spacing(2),
  },
  emailDesc: {
    backgroundColor: "#fff",
    marginBottom: theme.spacing(2),
  },
  btnSubmit: {
    backgroundColor: "#fff",
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
