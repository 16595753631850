import React from "react";
import { Grid, Container, Box, Typography, Link } from "@material-ui/core";
import Logo from "../../assets/img/logo-wh.png";
import ChseLogo from "../../assets/img/chse-logo.png";
import WonderfulLogo from "../../assets/img/wonderful-logo.png";
import useStyles from "./Footer.styles";
import { Analytics } from "..";

const Footer = () => {
  const classes = useStyles();
  const preventDefault = (event) => event.preventDefault();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} lg={5}>
              <Box mb={4}>
                <img src={Logo} alt="logo-wh" className={classes.logo} />
              </Box>
              <Typography variant="h4" paragraph>
                PT. WEHA Transportasi Indonesia, Tbk
              </Typography>
              <Typography
                variant="subtitle1"
                className={classes.desc}
                paragraph
              >
                A transportation service company integrated with the tourism
                industry.
              </Typography>
              {/* <Box display={"flex"}>
                <img
                  src={ChseLogo}
                  alt="chse-logo"
                  className={classes.additionalLogo}
                />
                <img
                  src={WonderfulLogo}
                  alt="wonderful-logo"
                  className={classes.additionalLogo}
                />
              </Box> */}
            </Grid>
            <Grid item xs={6} sm={4} lg={2}>
              <Box>
                <Typography
                  variant="h4"
                  className={classes.linkTitle}
                  paragraph
                >
                  Products
                </Typography>
                <Link
                  href="https://whitehorse.co.id"
                  target="_blank"
                  className={classes.link}
                  onClick={() => {
                    Analytics("products_wh_click", {
                      title: "Products Open Website White Horse",
                    });
                  }}
                >
                  White Horse
                </Link>
                <Link
                  href="https://daytrans.co.id"
                  target="_blank"
                  className={classes.link}
                  onClick={() => {
                    Analytics("products_daytrans_click", {
                      title: "Products Open Website Daytrans",
                    });
                  }}
                >
                  Daytrans
                </Link>
                <Link
                  href="https://explorer.id"
                  target="_blank"
                  className={classes.link}
                  onClick={() => {
                    Analytics("products_explorer_click", {
                      title: "Products Open Website Explorer",
                    });
                  }}
                >
                  Explorer.id
                </Link>
              </Box>
            </Grid>
            <Grid xs={6} sm={4} item lg={2}>
              <Box>
                <Typography
                  variant="h4"
                  className={classes.linkTitle}
                  paragraph
                >
                  About Us
                </Typography>
                <Link href="/hubungan-investor" className={classes.link}>
                  Investor Relations
                </Link>
                <Link href="/tentang-kami" className={classes.link}>
                  Management
                </Link>
                <Link href="/tentang-kami" className={classes.link}>
                  History
                </Link>
                <Link href="/penghargaan" className={classes.link}>
                  Awards
                </Link>
                {/* <Link
                  href="#"
                  onClick={preventDefault}
                  className={classes.link}
                >
                  Kegiatan Kemasyarakatan
                </Link> */}
              </Box>
            </Grid>
            <Grid xs={12} sm={4} item lg={3}>
              <Box>
                <Typography
                  variant="h4"
                  className={classes.linkTitle}
                  paragraph
                >
                  Contact Us
                </Typography>
                <Typography className={classes.link}>
                  Jl. Husein Sastranegara No.111, RT.5/RW.8, Rawa Bokor, Kec.
                  Benda, Kota Tangerang, Banten 15125
                </Typography>
                <Typography className={classes.link}>
                  Tel: +62 21 2967 5555
                </Typography>
                <Typography className={classes.link}>
                  Fax: +62 21 2967 5005
                </Typography>
                <Typography className={classes.link}>
                  Hotline Sales. +62 851 0055 1445
                </Typography>
                <Typography className={classes.link}>
                  WA: +62 813 1855 5578
                </Typography>
                <Link
                  href="https://whitehorse.co.id"
                  target="_blank"
                  className={classes.link}
                >
                  www.whitehorse.co.id
                </Link>
              </Box>
            </Grid>
          </Grid>
          <Box marginTop={3}>
            <Typography
              align="center"
              variant="body1"
              className={classes.copyright}
            >
              Copyright 2023 Alrights Reserved White Horse Group.
            </Typography>
          </Box>
        </Container>
      </Container>
    </div>
  );
};

export default Footer;
