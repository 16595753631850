export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const SET_LOADER = "SET_LOADER";

// Auth
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_CHANGE_PASSWORD = "SET_CHANGE_PASSWORD";
export const SET_FORGOT_PASSWORD_START = "SET_FORGOT_PASSWORD_START";
export const SET_FORGOT_PASSWORD_SUCCESS = "SET_FORGOT_PASSWORD_SUCCESS";
export const SET_FORGOT_PASSWORD_FAIL = "SET_FORGOT_PASSWORD_FAIL";
export const SET_RESET_PASSWORD_START = "SET_RESET_PASSWORD_START";
export const SET_RESET_PASSWORD_SUCCESS = "SET_RESET_PASSWORD_SUCCESS";
export const SET_RESET_PASSWORD_FAIL = "SET_RESET_PASSWORD_FAIL";
