import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  cardContainer: {
    boxShadow: "0px 4px 100px rgba(175, 173, 181, 0.25)",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  imgSkew: {
    width: "60%",
    height: "180px",
    // transform: "skew(-14deg, -6deg)",
    // overflow: "hidden",
    // marginLeft: 20,
    // display: "flex",
    // justifyContent: "center",
    // flexDirection: "row",
    // alignItems: "center",
  },
  img: {
    width: "180px",
    height: "180px",
    position: "absolute",
    top: 17,
    // transform: "skew(14deg, 6deg)",
    // position: "relative",
  },
  name: {
    [theme.breakpoints.only("md")]: {
      fontSize: 24,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 21,
    },
  },
  role: {
    [theme.breakpoints.only("md")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
    },
  },
}));

export default useStyles;
