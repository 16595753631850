import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    whiteSpace: "nowrap",
    paddingTop: 80,
    paddingBottom: 20,
    paddingLeft: 20,
  },
  container: {
    display: "inline-block",
    marginRight: "40px",
    position: "relative",
  },
  cardContainer: {
    boxShadow: "0px 4px 20px rgba(175, 173, 181, 0.25)",
    width: "293px",
  },
  img: {
    width: "117px",
    height: "120px",
  },
  imgContainer: {
    textAlign: "center",
    position: "absolute",
  },
}));

export default useStyles;
