import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: 150 },
  subtitle: {
    fontSize: "16px",
    color: "#939598",
  },
  productLogo: {
    width: 180,
  },
  productDesc: {
    fontSize: 15,
    color: "#484546",
    fontWeight: 400,
    letterSpacing: "0.04em",
  },
  seeMoreBtn: {
    color: "#231F20",
    fontWeight: 700,
    textDecoration: "underline",
    textAlign: "center",
    display: "block",
  },
  card: {
    height: 250,
  },
  btnIcon: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
}));

export default useStyles;
