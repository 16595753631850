import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  btnGroup: {
    backgroundColor: "#fff",
  },
  btn: {
    backgroundColor: "#fff",
    color: "#949598",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
  btnActive: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  content: {
    width: "100%",
    backgroundColor: "#F4F4F4",
    paddingTop: 24,
    paddingBottom: 50,
  },
}));

export default useStyles;
