import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  select: {
    width: 100,
  },
  downloadAll: {
    textTransform: "none",
  },
}));

export default useStyles;
