import {
  AUTH_START,
  AUTH_FAIL,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  SET_CHANGE_PASSWORD,
  SET_FORGOT_PASSWORD_SUCCESS,
  SET_FORGOT_PASSWORD_START,
  SET_FORGOT_PASSWORD_FAIL,
  SET_RESET_PASSWORD_SUCCESS,
  SET_RESET_PASSWORD_START,
  SET_RESET_PASSWORD_FAIL,
} from "../actions/actionTypes";
import { updateObject } from "../../utils";

const initialState = {
  token: localStorage.getItem("access_token") || null,
  data: JSON.parse(localStorage.getItem("data")) || null,
  loading: false,
  error: null,
  loadingForgotPassword: false,
  loadingResetPassword: false,
};

const authStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const forgotPasswordStart = (state, action) => {
  return updateObject(state, { loadingForgotPassword: true });
};

const forgotPasswordSuccess = (state, action) => {
  return updateObject(state, {
    loadingForgotPassword: false,
  });
};

const forgotPasswordFail = (state, action) => {
  return updateObject(state, {
    loadingForgotPassword: false,
  });
};

const resetPasswordStart = (state, action) => {
  return updateObject(state, { loadingResetPassword: true });
};

const resetPasswordSuccess = (state, action) => {
  return updateObject(state, {
    loadingResetPassword: false,
  });
};

const resetPasswordFail = (state, action) => {
  return updateObject(state, {
    loadingResetPassword: false,
  });
};

const changePassword = (state, action) => {
  return updateObject(state, { loading: false });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.payload.token,
    data: action.payload.data,
    loading: false,
  });
};

const authFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
    data: null,
    loading: false,
    error: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_START:
      return authStart(state, action);
    case AUTH_SUCCESS:
      return authSuccess(state, action);
    case AUTH_FAIL:
      return authFail(state, action);
    case AUTH_LOGOUT:
      return authLogout(state, action);
    case SET_CHANGE_PASSWORD:
      return changePassword(state, action);
    case SET_FORGOT_PASSWORD_START:
      return forgotPasswordStart(state, action);
    case SET_FORGOT_PASSWORD_SUCCESS:
      return forgotPasswordSuccess(state, action);
    case SET_FORGOT_PASSWORD_FAIL:
      return forgotPasswordFail(state, action);
    case SET_RESET_PASSWORD_START:
      return resetPasswordStart(state, action);
    case SET_RESET_PASSWORD_SUCCESS:
      return resetPasswordSuccess(state, action);
    case SET_RESET_PASSWORD_FAIL:
      return resetPasswordFail(state, action);
    default:
      return state;
  }
};

export default reducer;
