// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBkuGcN2MyvI1eSn-t7nHsiqt5yh5-qIY8",
  authDomain: "wehagroup-2dd92.firebaseapp.com",
  projectId: "wehagroup-2dd92",
  storageBucket: "wehagroup-2dd92.appspot.com",
  messagingSenderId: "958964184004",
  appId: "1:958964184004:web:4ff53abe8b312fd9c5aa91",
  measurementId: "G-NB84BNDHS6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const setAnalytics = (message, params) => {
  return logEvent(analytics, message, {
    ...params,
    unique_id: new Date().getTime(),
  });
};

export default setAnalytics;
