import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  grow: {
    flexGrow: 1,
  },
  navLink: {
    fontSize: 18,
    fontWeight: 500,
    color: "#484546",
    textTransform: "none",
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
    },
  },
  navLinkActive: {
    color: theme.palette.primary.main,
  },
  navbar: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(4),
    background: "transparent",
    boxShadow: "none",
  },
  mR: {
    marginRight: "80px",
  },
  logo: {
    width: "180px",
    height: "60px",
  },
  menu: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
  menuActive: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
}));

export default useStyles;
