import React, { Fragment } from "react";
import useStyles from "./Daytrans.styles";

import { Grid, Container, Typography, Box, Button } from "@material-ui/core";
import DaytransImg from "../../../../assets/img/intercity-shuttle.png";
import Logo from "../../../../assets/img/daytrans-logo.png";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Analytics } from "../../../../components";

const Daytrans = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <Box className={classes.root}>
        <Container maxWidth="lg">
          <Container maxWidth="lg">
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <img src={DaytransImg} className={classes.daytransImg} />
              </Grid>
              <Grid item xs={12} md={6}>
                <img src={Logo} className={classes.logo} />
                <Typography className={classes.title} variant="h4">
                  Intercity Shuttle
                </Typography>
                <Typography
                  paragraph
                  className={classes.desc}
                  variant="subtitle1"
                  align="justify"
                >
                  Daytrans is an intercity shuttle service that provides safe
                  and reliable transportation between cities in Java. We sell
                  seat tickets for our comfortable and modern buses, ensuring
                  that our passengers arrive at their destination feeling
                  refreshed and relaxed. In addition to our shuttle service, we
                  also offer a package delivery service that leverages our
                  transportation network to provide fast and efficient delivery
                  throughout the region. At Daytrans, we are committed to
                  providing the highest standards of service and ensuring that
                  our passengers and customers have a positive experience every
                  time they use our services.
                </Typography>
                <a href="https://daytrans.co.id" target="_blank">
                  <Button
                    onClick={() =>
                      Analytics("open_web_daytrans_click", {
                        title: "Open Website Daytrans",
                      })
                    }
                    variant="contained"
                    color="primary"
                    endIcon={<ChevronRightIcon />}
                  >
                    Open Website
                  </Button>
                </a>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </Box>
    </Fragment>
  );
};

export default Daytrans;
