import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  tripExplorerImg: {
    width: "100%",
  },
  logo: {
    width: 240,
    marginBottom: 50,
    [theme.breakpoints.down("sm")]: {
      width: 144,
      marginBottom: 30,
    },
  },
  title: {
    marginBottom: 50,
    fontWeight: 700,
    fontSize: 44,
    "& span": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 38,
      marginBottom: 30,
    },
  },
  desc: {
    color: "#484546",
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 30,
    },
  },
  root: {
    marginBottom: 100,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 80,
    },
  },
}));

export default useStyles;
