import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 120,
  },
  subtitle: {
    fontSize: "16px",
    color: "#939598",
  },
}));

export default useStyles;
