import React from "react";
import { Navigate, Routes as Switch, Route } from "react-router-dom";

import { MainLayout } from "./layouts";

import {
  Beranda as BerandaView,
  TentangKami as TentangKamiView,
  HubunganInvestor as HubunganInvestorView,
  Penghargaan as PenghargaanView,
  KegiatanKemasyarakatan as KegiatanKemasyarakatanView,
} from "./views";

const Routes = () => {
  let routes = (
    <Switch>
      {/* Beranda Page */}
      <Route
        path="/"
        exact
        element={
          <MainLayout>
            <BerandaView />
          </MainLayout>
        }
      />
      {/* TentangKami Page */}
      <Route
        path="/tentang-kami"
        exact
        element={
          <MainLayout>
            <TentangKamiView />
          </MainLayout>
        }
      />
      {/* HubunganInvestor Page */}
      <Route
        path="/hubungan-investor"
        exact
        element={
          <MainLayout>
            <HubunganInvestorView />
          </MainLayout>
        }
      />
      {/* Penghargaan Page */}
      <Route
        path="/penghargaan"
        exact
        element={
          <MainLayout>
            <PenghargaanView />
          </MainLayout>
        }
      />
      {/* Kegiatan Kemasyarakatan Page */}
      <Route
        path="/kegiatan-kemasyarakatan"
        exact
        element={
          <MainLayout>
            <KegiatanKemasyarakatanView />
          </MainLayout>
        }
      />

      {/* <Route to="/not-found" component={Error404} /> */}
    </Switch>
  );

  return routes;
};

export default Routes;
