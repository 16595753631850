import { makeStyles } from "@material-ui/styles";
import BgImage from "../../../../assets/img/beranda-img.png";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 120,

    [theme.breakpoints.down("sm")]: {
      marginBottom: 80,
    },
  },
  visiBg: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: 70,
    paddingBottom: 70,
  },
  subtitleVisi: {
    fontWeight: 400,
    color: "#CDCECE",
    fontSize: 16,
    marginBottom: 30,
  },
  titleVisi: {
    fontWeight: 700,
    fontSize: 44,
    color: "#fff",
    marginBottom: 30,
  },
  descVisi: {
    color: "#fff",
    fontWeight: 400,
  },
  subtitleMisi: {
    fontWeight: 400,
    color: "#CDCECE",
    fontSize: 16,
    marginBottom: 30,
  },
  titleMisi: {
    fontWeight: 700,
    fontSize: 44,
    color: "#231F20",
    marginBottom: 30,
  },
  descMisi: {
    color: "#939598",
    fontWeight: 400,
  },
}));

export default useStyles;
