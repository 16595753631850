import React from "react";
import { Snackbar } from "@material-ui/core";
import Alerts from "@material-ui/lab/Alert";

const Alert = (props) => {
  // const { open, payload } = props.alerts;
  const open = false;

  let snackBar = null;
  if (open) {
    snackBar = (
      <Snackbar
        open={true}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        // key={payload.id}
        // onClose={props.onCloseAlert}
      >
        <Alerts
          elevation={6}
          variant="filled"
          // onClose={props.onCloseAlert}
          // severity={payload.alertType}
        >
          {/* {payload.msg} */}
        </Alerts>
      </Snackbar>
    );
  }

  return <div>{snackBar}</div>;
};

export default Alert;
