import React from "react";
import useStyles from "./SejarahKami.styles";

import { Container, Typography, Box, Hidden } from "@material-ui/core";
import SejarahImg from "../../../../assets/img/sejarah.svg";
import SejarahImgMobile from "../../../../assets/img/sejarah-mobile.svg";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import { useRef } from "react";
import { useCallback } from "react";

const SejarahKami = () => {
  const classes = useStyles();
  const imgRef = useRef();
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });

      img.style.setProperty("transform", value);
    }
  }, []);

  return (
    <Box className={classes.root}>
      <Container maxWidth="xl">
        <Box textAlign={"center"} mb={3}>
          <Typography variant="h5" color="secondary">
            History
          </Typography>
        </Box>
        <Box textAlign={"center"} mb={5}>
          <Typography variant="h2">Our History</Typography>
        </Box>
        <Box textAlign={"center"} mb={9}>
          <Typography variant="subtitle1" className={classes.subtitle}>
            The company began its history in Jakarta in the 1960s when Adhi
            Tirtawisata started doing <br /> business in land transportation by
            operating four becak units named "Becak Jaksa".
          </Typography>
        </Box>
        <Box width={"100%"}>
          <Hidden mdDown>
            <QuickPinchZoom onUpdate={onUpdate}>
              <img
                ref={imgRef}
                src={SejarahImg}
                alt="sejarah-wh"
                className={classes.img}
              />
            </QuickPinchZoom>
          </Hidden>
          <Hidden lgUp>
            <QuickPinchZoom onUpdate={onUpdate}>
              <img
                ref={imgRef}
                src={SejarahImgMobile}
                alt="sejarah-wh"
                className={classes.img}
              />
            </QuickPinchZoom>
          </Hidden>
        </Box>
      </Container>
    </Box>
  );
};

export default SejarahKami;
