import React, { Fragment } from "react";
import useStyles from "./BusCharterServices.styles";

import { Grid, Container, Typography, Box, Button } from "@material-ui/core";
import BusCharterImg from "../../../../assets/img/bus-charter.png";
import Whlogo from "../../../../assets/img/logo-wh.png";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Analytics } from "../../../../components";

const BusCharterServices = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <Box className={classes.root}>
        <Container maxWidth="lg">
          <Container maxWidth="lg">
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <img src={BusCharterImg} className={classes.busCharterImg} />
              </Grid>
              <Grid item xs={12} md={6}>
                <img src={Whlogo} className={classes.whLogo} />
                <Typography className={classes.title} variant="h4">
                  Bus Charter Services
                </Typography>
                <Typography
                  className={classes.desc}
                  variant="subtitle1"
                  align="justify"
                  paragraph
                >
                  White Horse is a professional and reliable bus charter company
                  that provides safe and comfortable transportation services for
                  a variety of occasions. Whether you're planning a tour,
                  employee shuttle, or field trip, White Horse has the expertise
                  and resources to meet your needs. With a focus on customer
                  satisfaction and professionalism, White Horse is committed to
                  providing the highest standards of service and ensuring that
                  your transportation experience is enjoyable and stress-free.
                  Contact us today to learn more about our services and to book
                  your next trip!
                </Typography>
                <a href="https://whitehorse.co.id" target="_blank">
                  <Button
                    onClick={() =>
                      Analytics("open_web_wh_click", {
                        title: "Open Website White Horse",
                      })
                    }
                    variant="contained"
                    color="primary"
                    endIcon={<ChevronRightIcon />}
                  >
                    Open Website
                  </Button>
                </a>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </Box>
    </Fragment>
  );
};

export default BusCharterServices;
