import React, { Fragment } from "react";
import useStyles from "./ModalProduct.styles";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  Typography,
} from "@material-ui/core";

const ModalProduct = ({ open, handleClose, data }) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogContent style={{ padding: 38 }}>
        <Box textAlign="center" mb={4}>
          <img src={data?.logo} width={185} />
        </Box>
        <Typography align="justify" variant="body1">
          {data?.desc}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default ModalProduct;
