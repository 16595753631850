import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 182,
    [theme.breakpoints.down("sm")]: {
      width: 93,
    },
  },
  desc: {
    fontWeight: 400,
    color: "#939598",
    fontSize: 15,
    [theme.breakpoints.up("lg")]: {
      width: "350px",
    },
  },
  additionalLogo: {
    width: 148,
    height: "auto",
  },
  link: {
    fontWeight: 400,
    fontSize: 16,
    color: "#484546",
    display: "block",
    marginBottom: 20,
  },
  linkTitle: {
    fontWeight: 700,
    fontSize: 20,
    color: "#231F20",
  },
  copyright: {
    fontWeight: 400,
    fontSize: 14,
    color: "#484546",
  },
  root: {
    paddingBottom: 30,
  },
}));

export default useStyles;
