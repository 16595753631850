import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 50,
  },
  singleDownload: {
    textDecoration: "underline",
    textTransform: "none",
    fontWeight: 400,
    fontSize: 14,
    color: "#949598",
    padding: 0,
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "transparent",
    },
  },
  name: {
    flexGrow: 1,
    width: "10ch",
    [theme.breakpoints.only("sm")]: {
      fontSize: 17,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 19,
    },
  },
}));

export default useStyles;
