import React, { useState } from "react";
import useStyles from "./KegiatanKemasyarakatan.styles";

import { Container, Typography, Box, Grid, Button } from "@material-ui/core";

const KegiatanKemasyarakatan = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container maxWidth="xl">
        <Box textAlign={"center"} mb={3}>
          <Typography variant="h5" color="secondary">
            Kegiatan Kemasyarakatan
          </Typography>
        </Box>
        <Box textAlign={"center"} mb={5}>
          <Typography variant="h2">Kegiatan Kemasyarakatan</Typography>
        </Box>
        <Box textAlign={"center"} mb={9}>
          <Typography variant="subtitle1" className={classes.subtitle}>
            Pellentesque etiam blandit in tincidunt at donec. Eget ipsum
            dignissim placerat nisi, <br /> adipiscing mauris non purus
            parturient.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default KegiatanKemasyarakatan;
