import React, { useEffect, useState } from "react";
import useStyles from "./Rups.styles";

import {
  Container,
  Box,
  TextField,
  MenuItem,
  Grid,
  Button,
} from "@material-ui/core";
import FileItemRups from "../FileItemRups/FileItemRups";
import RUPS2022lamp2 from "../../../../../../assets/files/2022_Pengumuman_RUPS_lamp2.pdf";
import RUPS2022lamp1 from "../../../../../../assets/files/2022_Pengumuman_RUPS_lamp1.pdf";
import RUPS2023lamp1 from "../../../../../../assets/files/2023_Pengumuman_RUPS_31311749_lamp1.pdf";
import RUPS2023lamp2 from "../../../../../../assets/files/2023_Pengumuman_RUPS_31311749_lamp2.pdf";
import RUPSJuni2023 from "../../../../../../assets/files/2023_Hasil_RUPS-RUPSLB_27_Juni.pdf";
import RUPSJuni2024 from "../../../../../../assets/files/2024_Pengumuman_RUPS_27_Juni.pdf";
import RUPSJuniPanggilan2024 from "../../../../../../assets/files/2024_Panggilan_RUPS_WEHA.pdf";
import HASILRUPS_RUPSLBJuni2024 from "../../../../../../assets/files/2024_Hasil_RUPS_RUPSLB_27_Juni.pdf";
import JadwalPembagianDevidenJuni2024 from "../../../../../../assets/files/2024_Jadwal_Pembagian_deviden_Juni_2024.pdf";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";

const items = [
  {
    name: "2022 Pengumuman RUPS Lampiran 1",
    file: RUPS2022lamp1,
    year: "2022",
  },
  {
    name: "2022 Pengumuman RUPS Lampiran 2",
    file: RUPS2022lamp2,
    year: "2022",
  },
  {
    name: "2023 Pengumuman RUPS Lampiran 1",
    file: RUPS2023lamp1,
    year: "2023",
  },
  {
    name: "2023 Pengumuman RUPS Lampiran 2",
    file: RUPS2023lamp2,
    year: "2023",
  },
  {
    name: "2023 Hasil RUPS-RUPSLB 27 Juni",
    file: RUPSJuni2023,
    year: "2023",
  },
  {
    name: "2024 Pengumuman RUPS 27 Juni",
    file: RUPSJuni2024,
    year: "2024",
  },
  {
    name: "2024 Panggilan RUPS 27 Juni",
    file: RUPSJuniPanggilan2024,
    year: "2024",
  },
  {
    name: "2024 Hasil RUPS RUPSLB 27 Juni",
    file: HASILRUPS_RUPSLBJuni2024,
    year: "2024",
  },
  {
    name: "2024 Jadwal Pembagian Deviden 27 Juni",
    file: JadwalPembagianDevidenJuni2024,
    year: "2024",
  }
];

const Rups = ({ filterQuartalRups, filterYears }) => {
  const classes = useStyles();
  const [rupsData, setRupsData] = useState(items);
  const [rupsFilter, setRupsFilter] = useState({
    quartal: "",
    year: "",
  });
  const handleFilter = (data, props, type) => {
    setRupsFilter({ ...rupsFilter, [props]: data });
  };

  useEffect(() => {
    let tempData = items.filter((e) => {
      for (let key in rupsFilter) {
        if (rupsFilter[key] !== "" && e[key] !== rupsFilter[key]) {
          return false; // Skip objects that don't match the filter condition
        }
      }
      return true; // Include objects that pass all filter conditions
    });
    setRupsData(tempData);
  }, [rupsFilter]);
  return (
    <Container maxWidth="xl">
      <Box textAlign={"right"} mb={4}>
        {/* <Box mr={4} display={"inline"}>
          <TextField
            select
            className={classes.select}
            label="Quartal"
            value={rupsFilter.quartal}
            onChange={(e) =>
              handleFilter(e.target.value, "quartal",)
            }
          >
            {filterQuartalRups.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box> */}
        <Box display={"inline"}>
          <TextField
            select
            className={classes.select}
            label="Tahun"
            value={rupsFilter.year}
            onChange={(e) => handleFilter(e.target.value, "year")}
          >
            {filterYears.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
      <Grid container spacing={3}>
        {rupsData.map((e, i) => {
          return (
            <Grid key={i} item xs={12} sm={6} md={6} lg={4}>
              <FileItemRups data={e} />
            </Grid>
          );
        })}
      </Grid>
      {/* <Box mt={3}>
        <a href={"#"} download="MyExampleDoc" target="_blank">
          <Button
            variant="contained"
            color="primary"
            endIcon={<SystemUpdateAltIcon />}
            className={classes.downloadAll}
          >
            Download Semua
          </Button>
        </a>
      </Box> */}
    </Container>
  );
};

export default Rups;
