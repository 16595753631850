import React from "react";
import useStyles from "./RhombusCards.styles";

import { Typography, Box, Card, CardContent } from "@material-ui/core";

const RhombusCards = ({ name, role, image }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <div className={classes.imgSkew}>
        <img src={image} className={classes.img} />
      </div>
      <Card className={classes.cardContainer}>
        <CardContent>
          <Typography
            className={classes.name}
            align="right"
            gutterBottom
            variant="h5"
            component="h2"
          >
            {name}
          </Typography>
          <Typography
            align="right"
            variant="body1"
            color="textSecondary"
            component="p"
            className={classes.role}
          >
            {role}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default RhombusCards;
