import React from "react";
import useStyles from "./FileItem.styles";

import { Box, Button, Tooltip, Typography } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";

const FileItem = ({ data }) => {
  const classes = useStyles();
  return (
    <Box textAlign={"center"}>
      <FileCopyIcon className={classes.icon} />
      {data.name.length <= 28 ? (
        <Typography className={classes.title} paragraph variant="h5">
          {data.name}
        </Typography>
      ) : (
        <Tooltip title={data.name}>
          <Typography className={classes.title} paragraph variant="h5">
            {data.name.substring(0, 28) + "..."}
          </Typography>
        </Tooltip>
      )}
      <Typography color="textSecondary" paragraph variant="body1">
        {data.type}
      </Typography>
      <a href={data.file} download={data.name} target="_blank">
        <Button
          variant="contained"
          color="primary"
          className={classes.btnDownload}
          endIcon={<SystemUpdateAltIcon />}
        >
          Download
        </Button>
      </a>
    </Box>
  );
};

export default FileItem;
