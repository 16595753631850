import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer,
  Collapse,
} from "@material-ui/core";
import Logo from "../../assets/img/logo-wh.png";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import useStyles from "./MobileDrawer.styles";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import { useLocation } from "react-router-dom";
import CustomRouterLink from "../CustomRouterLink/CustomRouterLink";
import { Analytics } from "..";
const MobileDrawer = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const [state, setState] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const list = (anchor) => (
    <div className={clsx(classes.list)} role="presentation">
      <List>
        <ListItem
          button
          onClick={() => {
            setState(!state);
            Analytics("nav_home_click", {
              title: "/",
            });
          }}
          component={CustomRouterLink}
          to={`/`}
        >
          <ListItemText
            className={clsx(classes.navLink, {
              [classes.navLinkActive]: location.pathname === "/",
            })}
            primary="Home"
          />
        </ListItem>
        <ListItem
          button
          onClick={handleClick}
          className={clsx(classes.navLink, {
            [classes.navLinkActive]:
              location.pathname === "/tentang-kami" ||
              location.pathname === "/penghargaan" ||
              location.pathname === "/kegiatan-kemasyarakatan",
          })}
        >
          <ListItemText primary="About Us" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              onClick={() => {
                setState(!state);
                Analytics("nav_management_click", {
                  title: "/tentang-kami",
                });
              }}
              component={CustomRouterLink}
              to={`/tentang-kami`}
              button
              className={classes.nested}
            >
              <ListItemText className={classes.navLink} primary="Management" />
            </ListItem>
            <ListItem
              onClick={() => {
                setState(!state);
                Analytics("nav_history_click", {
                  title: "/tentang-kami",
                });
              }}
              component={CustomRouterLink}
              to={`/tentang-kami`}
              button
              className={classes.nested}
            >
              <ListItemText
                className={clsx(classes.navLink, {
                  [classes.navLinkActive]:
                    location.pathname === "/tentang-kami",
                })}
                primary="History"
              />
            </ListItem>
            <ListItem
              onClick={() => {
                setState(!state);
                Analytics("nav_awards_click", {
                  title: "/penghargaan",
                });
              }}
              component={CustomRouterLink}
              to={`/penghargaan`}
              button
              className={classes.nested}
            >
              <ListItemText
                className={clsx(classes.navLink, {
                  [classes.navLinkActive]: location.pathname === "/penghargaan",
                })}
                primary="Awards"
              />
            </ListItem>
            {/* <ListItem
              onClick={() => setState(!state)}
              component={CustomRouterLink}
              to={`/kegiatan-kemasyarakatan`}
              button
              className={classes.nested}
            >
              <ListItemText
                className={clsx(classes.navLink, {
                  [classes.navLinkActive]:
                    location.pathname === "/kegiatan-kemasyarakatan",
                })}
                primary="Kegiatan Kemasyarakatan"
              />
            </ListItem> */}
          </List>
        </Collapse>
        <ListItem
          onClick={() => {
            setState(!state);
            Analytics("nav_investor_relations_click", {
              title: "/hubungan-investor",
            });
          }}
          component={CustomRouterLink}
          to={`/hubungan-investor`}
          button
        >
          <ListItemText
            className={clsx(classes.navLink, {
              [classes.navLinkActive]:
                location.pathname === "/hubungan-investor",
            })}
            primary="Investor Relations"
          />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar>
          <img src={Logo} className={classes.logo} alt="Explorer-logo" />
          <div className={classes.grow} />
          <IconButton
            edge="end"
            className={classes.barIcon}
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        anchor={"right"}
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {list("right")}
      </SwipeableDrawer>
    </>
  );
};

export default MobileDrawer;
