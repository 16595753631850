import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFF",
    minHeight: "100vh",
  },
  main: {},
}));

export default useStyles;
