import React from "react";
import useStyles from "./AwardCards.styles";

import { Typography, Box, Card, CardContent } from "@material-ui/core";

const AwardCards = ({ data }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {data.map((item, i) => {
        return (
          <div key={i} className={classes.container}>
            <div
              className={classes.imgContainer}
              style={{
                top: item.id == 1 ? "-50px" : item.id == 4 ? "-80px" : "-70px",
                left:
                  item.id == 1
                    ? "25%"
                    : item.id == 4
                    ? "20%"
                    : item.id == 3
                    ? "30%"
                    : "25%",
              }}
            >
              <img
                src={item.logo}
                style={{
                  width:
                    item.id == 1 ? "147px" : item.id == 4 ? "180px" : "117px",
                  height:
                    item.id == 1 ? "100px" : item.id == 4 ? "160px" : "120px",
                }}
              />
            </div>
            <Card className={classes.cardContainer}>
              <CardContent style={{ paddingTop: 90, paddingBottom: 30 }}>
                <Typography
                  align="center"
                  gutterBottom
                  variant="h5"
                  component="h2"
                >
                  {item.name}
                </Typography>
                <Typography
                  align="center"
                  variant="body1"
                  color="textSecondary"
                  component="p"
                  style={{
                    wordWrap: "break-all !important",
                    whiteSpace: "normal",
                  }}
                >
                  {item.desc}
                </Typography>
              </CardContent>
            </Card>
          </div>
        );
      })}
    </Box>
  );
};

export default AwardCards;
