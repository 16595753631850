import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 150,
    marginBottom: 120,

    [theme.breakpoints.down("sm")]: {
      paddingTop: 110,
      marginBottom: 80,
    },
  },
  subtitle: {
    fontSize: "16px",
    color: "#939598",
  },
}));

export default useStyles;
