import React from "react";
import useStyles from "./ManajemenKami.styles";

import { Container, Typography, Box, Grid } from "@material-ui/core";
import { RhombusCards } from "./components";
import ManajemenImg1 from "../../../../assets/img/manajemen1.svg";
import ManajemenImg2 from "../../../../assets/img/manajemen2.svg";
import ManajemenImg3 from "../../../../assets/img/manajemen3.svg";
import ManajemenImg4 from "../../../../assets/img/manajemen4.svg";
import ManajemenImg5 from "../../../../assets/img/manajemen5.svg";
import ManajemenImg6 from "../../../../assets/img/manajemen6.svg";

const managementItems = [
  {
    id: 1,
    name: "Angreta Chandra",
    role: "Komisaris Utama",
    image: ManajemenImg2,
  },
  {
    id: 2,

    name: "Daniel Martinus",
    role: "Komisaris Independen",
    image: ManajemenImg3,
  },
  {
    id: 3,

    name: "Andrianto Putera Tirtawisata",
    role: "Direktur Utama",
    image: ManajemenImg1,
  },
  {
    id: 4,

    name: "Romy Firmangustri",
    role: "Direktur Oprasional",
    image: ManajemenImg5,
  },
  {
    id: 5,

    name: "Tiodora Amran Bonardy ",
    role: "Direktur Sales Marketing",
    image: ManajemenImg6,
  },
  {
    id: 6,

    name: "Edgar Surjadi",
    role: "Direktur Finance Acounting",
    image: ManajemenImg4,
  },
];

const ManajemenKami = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container maxWidth="xl">
        <Box textAlign={"center"} mb={3}>
          <Typography variant="h5" color="secondary">
            Management
          </Typography>
        </Box>
        <Box textAlign={"center"} mb={5}>
          <Typography variant="h2">Our Management</Typography>
        </Box>
        <Box textAlign={"center"} mb={9}>
          <Typography variant="subtitle1" className={classes.subtitle}>
            The composition of the Company's Board of Commissioners and Board of
            Directors in 2023 appointed <br /> in accordance with Decision of
            the AGMS/LB of PT WEHA Transportasi 2022.
          </Typography>
        </Box>
        <Grid justifyContent="center" container spacing={2}>
          {managementItems
            .filter((e) => e.id <= 2)
            .map((item, i) => {
              return (
                <Grid key={i} item xs={12} sm={6} md={6} lg={3}>
                  <RhombusCards
                    image={item.image}
                    name={item.name}
                    role={item.role}
                  />
                </Grid>
              );
            })}
        </Grid>
        <Grid container spacing={2}>
          {managementItems
            .filter((e) => e.id > 2)
            .map((item, i) => {
              return (
                <Grid key={i} item xs={12} sm={6} md={6} lg={3}>
                  <RhombusCards
                    image={item.image}
                    name={item.name}
                    role={item.role}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Container>
    </Box>
  );
};

export default ManajemenKami;
