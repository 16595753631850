const white = "#FFFFFF";
const black = "#231F20";

const palette = {
  black,
  white,
  primary: {
    contrastText: white,
    dark: "#EB2F36",
    main: "#ED1C24",
    light: "#EB2F36",
  },
  success: {
    contrastText: white,
    dark: "#1b9620",
    main: "#27D72E",
    light: "#52df57",
  },
  info: {
    contrastText: white,
    dark: "#7da5b2",
    main: "#B4EDFF",
    light: "#c3f0ff",
  },
  warning: {
    contrastText: white,
    dark: "#b2af72",
    main: "#FFFBA4",
    light: "#fffbb6",
  },
  error: {
    contrastText: white,
    dark: "#a13e3e",
    main: "#E65959",
    light: "#eb7a7a",
  },
};

export default palette;
