import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 50,
  },
  title: {
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
  },
  btnDownload: {
    textTransform: "none",
  },
}));

export default useStyles;
