import React, { Fragment } from "react";
import useStyles from "./HubunganInvestor.styles";
import { FormEmail, MainContent, MainSection } from "./components";

const HubunganInvestor = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <MainSection />
      <MainContent />
      <FormEmail />
    </Fragment>
  );
};

export default HubunganInvestor;
