import React, { useState } from "react";
import useStyles from "./MainContent.styles";

import { Container, Box, ButtonGroup, Button } from "@material-ui/core";
import clsx from "clsx";
import { Financial, Rups } from "./components";

const filterQuartalRups = [
  {
    value: "",
    label: "All",
  },
  {
    value: "q1",
    label: "Q1",
  },
  {
    value: "q2",
    label: "Q2",
  },
  {
    value: "q3",
    label: "Q3",
  },
  {
    value: "q4",
    label: "Q4",
  },
];

const filterType = [
  {
    value: "",
    label: "All",
  },
  {
    value: "Quartal",
    label: "Quartal",
  },
  { value: "Annual", label: "Annual" },
];
const filterYears = [
  {
    value: "",
    label: "All",
  },
  {
    value: "2024",
    label: "2024",
  },
  {
    value: "2023",
    label: "2023",
  },
  {
    value: "2022",
    label: "2022",
  },
  {
    value: "2021",
    label: "2021",
  },
  {
    value: "2020",
    label: "2020",
  },
  {
    value: "2019",
    label: "2019",
  },
  {
    value: "2018",
    label: "2018",
  },
  {
    value: "2017",
    label: "2017",
  },
  {
    value: "2016",
    label: "2016",
  },
];
const MainContent = () => {
  const classes = useStyles();
  const [tab, setTab] = useState(1);
  return (
    <Box className={classes.root}>
      <Container maxWidth="xl">
        <ButtonGroup
          size="large"
          variant="contained"
          aria-label="outlined primary button group"
        >
          <Button
            onClick={() => setTab(1)}
            className={clsx(classes.btn, {
              [classes.btnActive]: tab === 1,
            })}
          >
            Financial
          </Button>
          <Button
            onClick={() => setTab(2)}
            className={clsx(classes.btn, {
              [classes.btnActive]: tab === 2,
            })}
          >
            Rups
          </Button>
        </ButtonGroup>
      </Container>
      <Box className={classes.content}>
        <Container maxWidth="xl">
          {tab === 1 ? (
            <Financial filterYears={filterYears} filterType={filterType} />
          ) : (
            <Rups
              filterQuartalRups={filterQuartalRups}
              filterYears={filterYears}
            ></Rups>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default MainContent;
