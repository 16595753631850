import React, { Fragment, useState } from "react";
import useStyles from "./OurProducts.styles";

import {
  Grid,
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Link,
  Hidden,
  Button,
} from "@material-ui/core";
import WhLogo from "../../../../assets/img/logo-wh.png";
import DaytransLogo from "../../../../assets/img/daytrans-logo.png";
import ExplorerLogo from "../../../../assets/img/explorer-logo.png";
import { ModalProduct } from "./components";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const productsItem = [
  {
    id: 1,
    logo: WhLogo,
    desc: "White Horse is a professional and reliable bus charter company that provides safe and comfortable transportation services for a variety of occasions.",
    fullDesc:
      "White Horse is a professional and reliable bus charter company that provides safe and comfortable transportation services for a variety of occasions. Whether you're planning a tour, employee shuttle, or field trip, White Horse has the expertise and resources to meet your needs. With a focus on customer satisfaction and professionalism, White Horse is committed to providing the highest standards of service and ensuring that your transportation experience is enjoyable and stress-free. Contact us today to learn more about our services and to book your next trip!",
  },
  {
    id: 2,
    logo: DaytransLogo,
    desc: "Daytrans is an intercity shuttle service that provides safe and reliable transportation between cities in Java.",
    fullDesc:
      "Daytrans is an intercity shuttle service that provides safe and reliable transportation between cities in Java. We sell seat tickets for our comfortable and modern buses, ensuring that our passengers arrive at their destination feeling refreshed and relaxed. In addition to our shuttle service, we also offer a package delivery service that leverages our transportation network to provide fast and efficient delivery throughout the region. At Daytrans, we are committed to providing the highest standards of service and ensuring that our passengers and customers have a positive experience every time they use our services.",
  },
  {
    id: 3,
    logo: ExplorerLogo,
    desc: "Explorer.id is a digital tour and travel company that specializes in creating exciting and affordable domestic tour packages throughout Indonesia.",
    fullDesc:
      "Explorer.id is a digital tour and travel company that specializes in creating exciting and affordable domestic tour packages throughout Indonesia. We offer both weekly scheduled trips, which we call open trips, as well as private trips for those who prefer to travel with their own group. To ensure the highest level of comfort and safety for our passengers, we use seats from DayTrans and buses from White Horse, both of which are known for their reliability and quality. Let us take you on an unforgettable journey through Indonesia today!",
  },
];

const OurProducts = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [dataModal, setDataModal] = useState(null);
  const [products, setProducts] = useState(productsItem);
  const handleSeeMore = (data, event) => {
    event.preventDefault();
    setDataModal({
      logo: data.logo,
      desc: data.fullDesc,
    });
    setOpen(true);
  };

  const slideLeft = () => {
    const lastArray = products.pop();
    setProducts([lastArray, ...products]);
  };

  const slideRight = () => {
    setProducts([...products.slice(1), products[0]]);
  };
  const handleCloseModal = () => {
    setOpen(false);
    setDataModal(null);
  };

  return (
    <Fragment>
      <Box className={classes.root}>
        <Container maxWidth="lg">
          <Box textAlign={"center"} mb={3}>
            <Typography variant="h5" color="secondary">
              Products
            </Typography>
          </Box>
          <Box textAlign={"center"} mb={5}>
            <Typography variant="h2">Our Products</Typography>
          </Box>
          <Box textAlign={"center"} mb={6}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              We are part of today's spirit, where things comes with innovation
              and modernity are always <br /> part of us. Our modernity embedded
              on how we deliver our product and services.
            </Typography>
          </Box>
          <Container maxWidth="lg">
            <Hidden smDown>
              <Grid container spacing={2}>
                {products.map((product) => {
                  return (
                    <Grid item xs={12} md={4} lg={4} key={product.id}>
                      <Card className={classes.card}>
                        <CardContent>
                          <Box textAlign={"center"} mb={3}>
                            <img
                              src={product.logo}
                              alt="logo=products"
                              className={classes.productLogo}
                            />
                          </Box>
                          <Typography
                            variant="body1"
                            align="center"
                            className={classes.productDesc}
                          >
                            {product.desc}
                          </Typography>
                          <Link
                            href="#"
                            onClick={(e) => handleSeeMore(product, e)}
                            className={classes.seeMoreBtn}
                          >
                            See More
                          </Link>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} lg={4}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Box textAlign={"center"} mb={3}>
                        <img
                          src={products[0].logo}
                          alt="logo=products"
                          className={classes.productLogo}
                        />
                      </Box>
                      <Typography
                        variant="body1"
                        align="center"
                        className={classes.productDesc}
                      >
                        {products[0].desc}
                        <Link
                          href="#"
                          onClick={(e) => handleSeeMore(products[0], e)}
                          className={classes.seeMoreBtn}
                        >
                          {" "}
                          See More
                        </Link>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  item
                  direction="row"
                  container
                  justifyContent="flex-end"
                  xs={12}
                >
                  <Button
                    size="large"
                    color="primary"
                    className={classes.btnIcon}
                    onClick={slideLeft}
                  >
                    <ArrowBackIcon />
                  </Button>
                  <Button
                    color="primary"
                    size="large"
                    className={classes.btnIcon}
                    onClick={slideRight}
                  >
                    <ArrowForwardIcon />
                  </Button>
                </Grid>
              </Grid>
            </Hidden>
          </Container>
        </Container>
      </Box>
      <ModalProduct
        open={open}
        handleClose={handleCloseModal}
        data={dataModal}
      />
    </Fragment>
  );
};

export default OurProducts;
