import React, { useRef, useState } from "react";
import useStyles from "./FormEmail.styles";
import emailjs from "@emailjs/browser";

import {
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import Swal from "sweetalert2";

const FormEmail = () => {
  const classes = useStyles();
  const form = useRef();
  const [loading, setLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm(
        "service_rupyt3a",
        "template_2qvih8c",
        form.current,
        "QQo4N-Jkf0k-uP_Ag"
      )
      .then(
        (result) => {
          console.log(result.text);
          Swal.fire("Success", "Email Berhasil Terkirim!", "success");
        },
        (error) => {
          console.log(error.text);
        }
      )
      .finally(() => {
        e.target.reset();
        setLoading(false);
      });
  };

  return (
    <Box className={classes.root}>
      <Container maxWidth="lg">
        <form ref={form} onSubmit={sendEmail}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={4} lg={3}>
              <Typography
                paragraph
                variant="body1"
                align="left"
                className={classes.subtitle}
              >
                Get in touch with us
              </Typography>
              <Typography align="left" variant="h5" className={classes.title}>
                Don’t miss any update
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Enter Email"
                className={classes.email}
                type="email"
                name="email"
                required
              />
              <TextField
                variant="outlined"
                fullWidth
                multiline
                name="message"
                minRows={5}
                required
                type="text"
                placeholder="Write a message..."
                className={classes.emailDesc}
              />
            </Grid>
            <Grid
              xs={12}
              container
              md={10}
              justifyContent="flex-end"
              item
              lg={7}
            >
              <Button
                className={classes.btnSubmit}
                variant="contained"
                color="default"
                type="submit"
                disabled={loading}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default FormEmail;
