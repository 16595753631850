import React from "react";
import useStyles from "./VisiMisi.styles";

import { Container, Typography, Box, Grid } from "@material-ui/core";

const VisiMisi = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box className={classes.visiBg}>
            <Container maxWidth="lg">
              <Typography
                paragraph
                variant="subtitle1"
                className={classes.subtitleVisi}
              >
                Vision
              </Typography>
              <Typography variant="h3" className={classes.titleVisi}>
                Vision
              </Typography>
              <Typography variant="body1" className={classes.descVisi}>
                "To be one of the best transportation operators in Indonesia".
              </Typography>
            </Container>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box paddingBottom={"70px"} paddingTop={"70px"}>
            <Container maxWidth="lg">
              <Typography
                paragraph
                variant="subtitle1"
                className={classes.subtitleMisi}
              >
                Mission
              </Typography>
              <Typography variant="h3" className={classes.titleMisi}>
                Mission
              </Typography>
              <Typography variant="body1" className={classes.descMisi}>
                "Providing integrated land, sea and air transportation services
                supported by quality facilities, security and services".
              </Typography>
            </Container>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VisiMisi;
