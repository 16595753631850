import React, { Fragment } from "react";
import useStyles from "./TentangKami.styles";
import { ManajemenKami, SejarahKami, VisiMisi } from "./components";

const TentangKami = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <SejarahKami />
      <VisiMisi />
      <ManajemenKami />
    </Fragment>
  );
};

export default TentangKami;
