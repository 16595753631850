import React from "react";
import useStyles from "./FileItemRups.styles";

import { Box, Link, Typography } from "@material-ui/core";

const FileItemRups = ({ data }) => {
  const classes = useStyles();
  return (
    <Box display={"flex"}>
      <Typography className={classes.name} paragraph variant="h5">
        {data.name}
      </Typography>
      <a href={data.file} download={data.name} target="_blank">
        <Link className={classes.singleDownload}>Klik untuk Download</Link>
      </a>
    </Box>
  );
};

export default FileItemRups;
