import { makeStyles } from "@material-ui/styles";
import BgImage from "../../../../assets/img/beranda-img.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url('${BgImage}')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "47rem",
    backgroundPosition: "center",
    paddingTop: 200,
    marginBottom: 330,
    [theme.breakpoints.only("md")]: {
      height: "43rem",
    },
    marginBottom: 330,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 130,
      height: "34rem",
      marginBottom: 220,
    },
    [theme.breakpoints.only("xs")]: {
      marginBottom: 130,
    },
  },
  title: {
    fontWeight: 700,
    fontSize: 48,
    lineHeight: "60px",
  },
  subtitle: {
    fontSize: 20,
    fontWeight: 400,
    color: "#484546",
    marginBottom: theme.spacing(15),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(10),
    },
  },
  mainImg: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export default useStyles;
