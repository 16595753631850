import React, { Fragment } from "react";
import useStyles from "./Beranda.styles";
import {
  BusCharterServices,
  Daytrans,
  HeroSection,
  OurProducts,
  TripExplorer,
} from "./components";

const Beranda = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <HeroSection />
      <OurProducts />
      <BusCharterServices />
      <TripExplorer />
      <Daytrans />
    </Fragment>
  );
};

export default Beranda;
