import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Container,
  Menu,
  MenuItem,
} from "@material-ui/core";
import Logo from "../../assets/img/logo-wh.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import clsx from "clsx";

import useStyles from "./Navbar.styles";
import CustomRouterLink from "../CustomRouterLink";
import { Analytics } from "..";

const Navbar = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleAboutUsMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAboutUsMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleAboutUsMenuClose}
    >
      <MenuItem
        className={classes.menu}
        onClick={() => {
          navigate("/tentang-kami");
          handleAboutUsMenuClose();
          Analytics("nav_management_click", {
            title: "/tentang-kami",
          });
        }}
      >
        Management
      </MenuItem>
      <MenuItem
        className={clsx(classes.menu, {
          [classes.menuActive]: location.pathname === "/tentang-kami",
        })}
        onClick={() => {
          navigate("/tentang-kami");
          handleAboutUsMenuClose();
          Analytics("nav_history_click", {
            title: "/tentang-kami",
          });
        }}
      >
        History
      </MenuItem>
      <MenuItem
        className={clsx(classes.menu, {
          [classes.menuActive]: location.pathname === "/penghargaan",
        })}
        onClick={() => {
          navigate("/penghargaan");
          handleAboutUsMenuClose();
          Analytics("nav_awards_click", {
            title: "/penghargaan",
          });
        }}
      >
        Awards
      </MenuItem>
      {/* <MenuItem
        className={clsx(classes.menu, {
          [classes.menuActive]:
            location.pathname === "/kegiatan-kemasyarakatan",
        })}
        onClick={() => navigate("/kegiatan-kemasyarakatan")}
      >
        Kegiatan Kemasyarakatan
      </MenuItem> */}
    </Menu>
  );

  return (
    <div>
      <AppBar position="absolute" className={classes.navbar}>
        <Container maxWidth="lg">
          <Container maxWidth="lg">
            <Toolbar disableGutters>
              <Link
                to="/"
                onClick={() =>
                  Analytics("logo_click", {
                    title: "/",
                  })
                }
              >
                <img src={Logo} className={classes.logo} />
              </Link>
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                <Button
                  className={clsx(classes.navLink, classes.mR, {
                    [classes.navLinkActive]: location.pathname === "/",
                  })}
                  color="primary"
                  component={CustomRouterLink}
                  to={`/`}
                  variant="text"
                  onClick={() =>
                    Analytics("nav_home_click", {
                      title: "/",
                    })
                  }
                >
                  Home
                </Button>
                <Button
                  className={clsx(classes.navLink, classes.mR, {
                    [classes.navLinkActive]:
                      location.pathname === "/tentang-kami" ||
                      location.pathname === "/penghargaan" ||
                      location.pathname === "/kegiatan-kemasyarakatan",
                  })}
                  color="primary"
                  component={CustomRouterLink}
                  variant="text"
                  onClick={handleAboutUsMenuOpen}
                  endIcon={<ExpandMoreIcon />}
                >
                  About us
                </Button>
                <Button
                  className={clsx(classes.navLink, classes.mR, {
                    [classes.navLinkActive]:
                      location.pathname === "/hubungan-investor",
                  })}
                  color="primary"
                  component={CustomRouterLink}
                  to={`/hubungan-investor`}
                  variant="text"
                  onClick={() =>
                    Analytics("nav_investor_relations_click", {
                      title: "/hubungan-investor",
                    })
                  }
                >
                  Investor Relations
                </Button>
              </div>
            </Toolbar>
          </Container>
        </Container>
      </AppBar>
      {renderMenu}
    </div>
  );
};

export default Navbar;
