import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "96px",
    height: "38px",
  },
  appBar: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  barIcon: {
    color: "#130F26",
  },
  grow: {
    flexGrow: 1,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },

  navLink: {
    textTransform: "none",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  navLinkActive: {
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
